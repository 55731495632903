<template>
  <b-row class="navbar" align-h="center">
    <ul>
      <li v-for="(item, index) in items" :key="index" :class="{ active1: current===index && index === 0 ,active2: current===index && index === 1  }" tabindex="1" @click="handleClickTab(item ,index)">
        <div>{{ item.label }}</div>
        <div class="triangle triangle-left"></div>
        <div class="triangle triangle-right"></div>
      </li>
    </ul>
  </b-row>
</template>

<script>
import commonmix from '@/utils/commonmix'

export default {
  mixins: [commonmix],
  name: 'Navbar',
  props: {
    items: {
      type: Array,
      default: () => []
    }
  },
  data () {
    return {
      current: 0
    }
  },
  methods: {
    handleClickTab (item, index) {
      if (this.$store.getters.token) {
        this.current = index
        this.$emit('handle-navbar', item)
      } else {
        this.openLoginModal()
      }
    }
  }
}
</script>

<style lang="scss" scoped>
$tab-color: #f5f5f5;
$tab-active-color1: #2b915c;
$tab-active-color2: #e27516;
// 导航
.navbar {
  margin: 0;
  padding: 0;
  display: flex;
  justify-content: flex-start!important;
}
.navbar > ul {
  margin: 0;
  padding: 20px 0 0 70px;
  list-style: none;
}
.navbar > ul > li {
  float: left;
  width: 96px;
  height: 40px;
  text-align: center;
  line-height: 40px;
  margin: 0 10px;
  background: $tab-color;
  border-radius: 10px 10px 0 0;
  position: relative;
  cursor: pointer;
  font-size: 14px;
  font-weight: bold;
}

.navbar > ul > li > a {
  color: #000000;
  font-weight: bold;
  text-decoration: none;
}

// 三角形
.triangle {
  width: 0;
  height: 0;
  position: absolute;
  border: 5px solid $tab-color;
  border-top-color: transparent!important;
  bottom: 0;
}
.triangle-left {
  border-left-color: transparent!important;
  left: -5px;
}
.triangle-right {
  border-right-color: transparent!important;
  right: -5px;
}

// 点击
.active1 {
  background-color: $tab-active-color1!important;
}
.active1 > div:first-child {
  color: #ffffff!important;
}
.active1 > .triangle-left, .active1 > .triangle-right {
  border: 5px solid $tab-active-color1;
}

.active2 {
  background-color: $tab-active-color2!important;
}
.active2 > div:first-child {
  color: #ffffff!important;
}
.active2 > .triangle-left, .active2 > .triangle-right {
  border: 5px solid $tab-active-color2;
}

</style>
