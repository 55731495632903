<template>
  <div class="date-picker-box">
    <div v-for="(item, index) in dataVisible"
         :key="index"
         :class="{ active: currentVisible === index }"
         :style="[ { backgroundColor:(currentVisible === index ? color : '#ffffff') } ]"
         class="hand"
         @click="handleTabItem(index)">

      <div class="point-box">
        <div :class="{ week: currentVisible === index }">{{ item.week }}</div>
        <span>{{ item.day }}</span>
        <div :class="{ point: currentVisible === index }"/>
      </div>
    </div>
  </div>
</template>
<script>

import commonmix from '@/utils/commonmix'

export default {
  mixins: [commonmix],
  name: 'Selector',
  props: {
    current: {
      type: Number,
      default: 6
    },
    data: {
      type: Array,
      default: () => []
    },
    start: {
      type: String,
      default: ''
    },
    color: {
      type: String,
      default: '#2b915c'
    },
    isEnExpire: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    currentVisible: {
      get () {
        return this.current
      },
      set (val) {
        this.$emit('update:current', val)
      }
    },
    startVisible: {
      get () {
        return this.start
      },
      set (val) {
        this.$emit('update:start', val)
      }
    },
    dataVisible: {
      get () {
        const arr = []
        this.data.forEach(item => {
          arr.push({ week: this.getWeek(item), data: item, day: this.dayjs(item).format('DD') })
        })
        return arr.reverse()
      },
      set (val) {
        this.$emit('update:data', val)
      }
    }
  },
  data () {
    return {
    }
  },
  methods: {
    handleTabItem (index) {
      if (this.$store.getters.token) {
        if (!this.isEnExpire) {
          this.openMembershipModal()
          return
        }
        this.startVisible = this.dataVisible[index].data
        this.currentVisible = index
      } else {
        this.openLoginModal()
      }
    },
    getWeek (dateString) {
      const a = ['Su', 'Mo', 'Tu', 'We', 'Th', 'Fr', 'Sa']
      const b = new Date(dateString)
      const week = b.getDay()
      return a[week]
    }
  }
}
</script>

<style lang="scss" scoped>

.active {
  width: 39px;
  height: 64px;
  color: #ffffff;
  border-radius: 10px;
}

.date-picker-box {
  margin: 20px 0;
  display: flex;
  align-items: center;
  font-size: 14px;
  font-weight: bold;
}
.date-picker-box > div {
  margin: 0 15px;
}
.point-box{
  width: 39px;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  padding: 4px 10px;
}

.point-box > div:first-child{
  opacity: 0.1;
}

.week{
  opacity: 1!important;
  font-weight: initial!important;
}

.point {
  text-align: center;
  margin: 0;
  height: 2px;
  content: "";
  border: 2px solid;
  border-radius: 2px;
  background-color: #ffffff;
}
</style>
