<template>
  <div id="main" style="height: 400px"></div>
</template>
<script>
import * as echarts from 'echarts'
import commonmix from '@/utils/commonmix'

export default {
  mixins: [commonmix],
  name: 'CnBtcDataTable',
  props: {
    data: {
      type: Object,
      default: () => {}
    },
    color: {
      type: Object,
      default: () => {}
    },
    legend: {
      type: Object,
      default: () => ({
        icon: 'roundRect',
        itemWidth: 10,
        itemHeight: 10,
        left: 150,
        top: 15,
        selectedMode: false
      })
    },
    title: {
      type: String,
      default: ''
    },
    yAxisName: {
      type: String,
      default: 'RMB/kg'
    },
    isEnExpire: {
      type: Boolean,
      default: true
    }
  },
  data () {
    return {
      myChart: null,
      option: {
        title: {
          text: this.title,
          left: 'center'
        },
        colortxt: '#D40C0C',
        grid: {
          left: '3%',
          right: '4%',
          bottom: '14%',
          containLabel: true
        },
        animation: false,
        tooltip: {
          show: this.$store.getters.token,
          trigger: 'axis'
          // formatter: function (params) {
          //   const formatterStr = '必孚指数<br />' + '<span style="display:inline-block;margin-right:5px;border-radius:50%;width:10px;height:10px;left:5px;background-color:#D40C0C"></span>' + params[0].name +
          //   '<span style="margin-right:30px"></span>' + params[0].value
          // }
        },
        legend: '', // this.legend,
        xAxis: {
          type: 'category',
          data: this.data.categories
        },
        yAxis: {
          // name: '', // this.yAxisName,
          // type: 'value',
          type: 'value',
          // min: (value) => { // 百位起最小值向下取整
          //   return Math.floor(value.min / 100) * 100
          // },
          // max: (value) => { // 百位起最大值向上取整
          //   return Math.ceil(value.max / 100) * 100
          // },
          min: 50,
          max: 110,
          // boundaryGap: true,
          splitNumber: 5,
          interval: 15
          // boundaryGap: [0.2, 0.2],
          // data: [65, 80, 95, 110, 125],
          // scale: true // 自适应
        },
        dataZoom: [{
          type: 'inside',
          start: 0,
          end: 100
        }, {
          start: 0,
          end: 100,
          height: 15,
          bottom: 20
        }],
        series: this.getSeries(this.data.series)
      }
    }
  },
  mounted () {
    this.init()
  },
  computed: {
    chartDom () {
      return document.getElementById('main')
    }
  },
  methods: {
    init () {
      const colorTxt = this.color.Color1
      this.option.tooltip.formatter = function (params) {
        const formatterStr = 'BTC Index<br />' + '<span style="display:inline-block;margin-right:5px;border-radius:50%;width:10px;height:10px;left:5px;background-color:' + colorTxt + '"></span>' + params[0].name +
            '<span style="margin-right:30px"></span>' + params[0].value
        return formatterStr
      }
      this.myChart = echarts.init(this.chartDom)
      this.myChart.setOption(this.option)

      const zr = this.myChart.getZr()
      zr.on('click', (params) => {
        const pointInPixel = [params.offsetX, params.offsetY]
        const pointInGrid = this.myChart.convertFromPixel('grid', pointInPixel)
        if (this.myChart.containPixel('grid', pointInPixel)) {
          const chartX = this.myChart.getOption().xAxis[0].data[pointInGrid[0]]
          if (this.$store.getters.token) {
            if (this.isEnExpire) {
              this.$emit('getChartX', chartX)
            } else {
              this.openCnMembershipModal()
            }
          } else {
            this.openCnLoginModal()
          }
        }
      })
    },
    getSeries (data) {
      // 长度等于1，折线图
      if (data != null && data.length >= 1) {
        return data.map(v => {
          v.itemStyle = { color: this.color.Color1 }
          v.symbol = 'none'
          return v
        })
      }
    },
    getAreaColor (colors) {
      return new echarts.graphic.LinearGradient(0, 0, 0, 1, [{
        offset: 0,
        color: colors.Color1
      }, {
        offset: 1,
        color: colors.Color2
      }])
    },
    refreshData (data) {
      const opt = this.myChart.getOption()
      opt.series = this.getSeries(data.series)
      opt.xAxis = {
        type: 'category',
        data: data.categories
      }
      opt.yAxis = {
        name: this.yAxisName,
        type: 'value'
      }
      opt.title = {
        text: this.title,
        left: 'center'
      }
      this.myChart.setOption(opt, true)
    }
  }
}
</script>

<style lang="scss" scoped>
</style>
