<template>
  <div class="media-card-box hand" @click="handleItem">
    <div class="header">
      <div class="date">
        <img src="@/assets/image/price/Date.png" alt="">
        <span>{{ handleDate(item.ReportTitle) }}</span>
        <div v-if="item.IsFree" class="free">Free</div>
      </div>
    </div>
    <div class="viewer" style="word-break: break-all;">
      <span>{{ content }}</span>
    </div>
    <!-- <div class="viewer" style="word-break: break-all;">
      <viewer v-if="item.ReportContent" :initialValue="content"/>
    </div> -->
    <div class="btn-group">
      <div>
        <img v-for="(item, index) in langs" :key="index" :src="item.icon" :alt="item.lang" class="hand">
      </div>
      <div>
        <img v-show="item.VideoLink" src="@/assets/image/price/analysis/video.png" alt="">
        <img src="@/assets/image/price/analysis/check.png" alt="">
      </div>
    </div>
  </div>
</template>

<script>
// import { Viewer } from '@toast-ui/vue-editor'
import commonmix from '@/utils/commonmix'

export default {
  mixins: [commonmix],
  name: 'MediaCard',
  components: {
    // Viewer
  },
  props: {
    item: {
      type: Object,
      default: () => {}
    }
  },
  data () {
    return {
      langIcon: [
        { lang: 'en', icon: require('@/assets/image/btcinfo/american.png') },
        { lang: 'spanish', icon: require('@/assets/image/btcinfo/spanish.png') },
        { lang: 'portuguese', icon: require('@/assets/image/btcinfo/portuguese.png') }
      ]
    }
  },
  computed: {
    content () {
      return this.item.ReportContent ? (this.item.ReportContent.substr(5, 85) + '...') : ''
    },
    isEnExpire () {
      return this.getUserProfile(this.$t('message._beefprice_en'))
    },
    langs () {
      const newArr = [...this.item.Langs]
      if (newArr.length !== 0) {
        newArr.unshift('en')
      }
      return this.langIcon.filter(v => {
        return newArr.includes(v.lang)
      })
    }
  },
  methods: {
    handleDate (str) {
      if (str) {
        return str.substring(str.lastIndexOf('(') + 1, str.lastIndexOf(')'))
      }
    },
    handleItem () {
      if (this.$store.getters.token) {
        if (this.isEnExpire || this.item.IsFree) {
          this.$emit('handleMedia', this.item)
        } else {
          this.openMembershipModal()
        }
      } else {
        this.openLoginModal()
      }
    }
  }
}
</script>

<style>
.viewer .tui-editor-contents > h4 {
  font-size: 12px!important;
  font-weight: normal!important;
}
</style>

<style lang="scss" scoped>
.media-card-box {
  width: 300px;
  margin: 10px 5px;
  height: 105px;
  background: #ffffff;
  border-radius: 8px;
  font-size: 12px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  box-shadow: 0 0 6px #e1e1e1;
}

.media-card-box .header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.media-card-box .header > div {
  display: flex;
  align-items: center;
}

.media-card-box > div:nth-child(2){
  padding: 0 10px;
}

.media-card-box .header > span {
  margin-right: 10px;
}

.date > img {
  width: 20px;
  height: 20px;
  margin: 10px;
}

.date > .free {
  background: #e32229;
  border-radius: 5px;
  color: #ffffff;
  padding: 0 5px;
  transform: scale(0.8);
  margin-left: 10px;
}
.btn-group {
  padding: 5px 10px 10px 10px;
  display: flex;
  justify-content: space-between;
}
.btn-group > div {
  display: flex;
  justify-content: flex-end;
}

.btn-group >  div:first-child{
  display: flex;
  justify-content: flex-start;
}

.btn-group > div:first-child > img {
  height: 12px;
  width: 20px;
  margin-right: 8px;
}
</style>
