<template>
  <div class="date-picker-box" :class="selectedVariant === 'success' ? 'date-pick-success' : selectedVariant === 'warning' ? 'date-pick-warning' : 'date-pick-danger'">
    <b-form-datepicker
      v-model="startVisible"
      :min="min"
      :max="max"
      :date-disabled-fn="dateDisabled"
      :date-format-options="{ year: 'numeric', month: 'short', day: '2-digit' }"
      :selected-variant = "selectedVariant"
      :disabled="isLogin"
      size="sm"
      locale="en"
      @input="handleInput"/>
  </div>
</template>
<script>

export default {
  name: 'Selector',
  props: {
    selectedVariant: {
      type: String,
      default: () => ''
    },
    defaultDate: {
      type: Array,
      default: () => []
    },
    start: {
      type: String,
      default: () => ''
    },
    isLogin: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    startVisible: {
      get () {
        return this.start
      },
      set (val) {
        this.$emit('update:start', val)
      }
    },
    min () {
      return this.defaultDate[this.defaultDate.length - 1].substring(0, 4) + '-01-01'
    },
    max () {
      return this.defaultDate[0].substring(0, 4) + '-12-31'
    }
  },
  data () {
    return {
      end: ''
    }
  },
  methods: {
    dateDisabled (ymd, date) {
      return !(this.defaultDate.indexOf(this.dayjs(date).format('YYYY-MM-DD')) > -1)
    },
    handleInput (item) {
      this.$emit('input', item)
    }
  }
}
</script>

<style lang="scss">
$cell-warning: #e27516;
$cell-success: #2b915c;
$cell-danger: #cc2020;

.small {
  display: none;
}

.date-picker-box {
  .btn-warning:not(:disabled):not(.disabled):active, .btn-warning:not(:disabled):not(.disabled).active, .show > .btn-warning.dropdown-toggle {
    color: #212529;
    background-color: $cell-warning;
    border-color: $cell-warning;
  }

  .btn-success:not(:disabled):not(.disabled):active, .btn-success:not(:disabled):not(.disabled).active, .show > .btn-success.dropdown-toggle {
    color: #fff;
    background-color: $cell-success;
    border-color: $cell-success;
  }

  .btn-danger:not(:disabled):not(.disabled):active, .btn-danger:not(:disabled):not(.disabled).active, .show > .btn-danger.dropdown-toggle {
    color: #fff;
    background-color: $cell-danger;
    border-color: $cell-danger;
  }

  .rounded-circle {
    border-radius: 20% !important;
  }
}

.date-pick-warning {
  .btn-outline-secondary:hover {
    color: #fff;
    background-color: $cell-warning;
    border-color: $cell-warning;
  }
}

.date-pick-success {
  .btn-outline-secondary:hover {
    color: #fff;
    background-color: $cell-success;
    border-color: $cell-success;
  }
}

.date-pick-danger {
  .btn-outline-secondary:hover {
    color: #fff;
    background-color: $cell-danger;
    border-color: $cell-danger;
  }
}
</style>

<style lang="scss" scoped>
.b-form-datepicker {
  border-radius: 30px;
  border: 1px solid #5f6162;
}

.date-picker-box {
  display: flex;
  align-items: center;
}
.date-picker-box > span {
  margin: 0 10px;
}
</style>
