<template>
  <div @click="handleCloseSelect">
    <div class="selector-calendar">
      <div style="width: 175px;">
        <selector-datetime :start.sync="date" selectedVariant="danger" :is-en-expire="isEnExpire" :defaultDate="defaultDate" :is-login="isPriceLogin" @input="handleInput"/>
      </div>
      <img src="@/assets/image/price/danger/calendar.png" style="margin-left: 20px" alt="">
    </div>
    <div style="margin: 0;padding: 0" title="The futures prices are updating weekly.">
      <datetime :current.sync="current" :start.sync="date" :data="dateTimeList" :is-en-expire="isEnExpire" color="#cc2020"/>
    </div>
    <div class="selector-box">
      <div class="selector-left">
        <selector :data="country" :is-show="isShow1" :name="defaultName1" :current.sync="current1" :is-en-expire="isEnExpire" @click.native="handleSelector(1)" @handleTabItem="handleTabItem($event, 1)"/>
        <selector :data="cuts" :is-show="isShow2" :name="defaultName2" :current.sync="current2" :is-en-expire="isEnExpire"  @click.native="handleSelector(2)" @handleTabItem="handleTabItem($event, 2)"/>
      </div>
      <div class="selector-left lower-height">
        <div class="selector-left">
          <div class="point" style="color:#2b915c;"/>
          <div>Represents a lower price</div>
        </div>
        <div class="selector-left">
          <div class="point" style="color:#c10b0b;"/>
          <div>Represents a higher price</div>
        </div>
      </div>
    </div>
    <!--表格部分-->
    <div class="header">
      <div v-for="(item, index) in items" :key="index">
        <img class="" :src="item.icon">
        <span :class="{ 'ml-10': item.icon }">{{ item.label }}</span>
      </div>
    </div>
    <div class="table-box">
      <div v-for="(item, index) in tableList" :key="index" class="table-item">
        <span>{{ item.OriginPlace }}</span>
        <span>{{ item.Part }}</span>
        <span class="rmb" @click="handleIsEnExpire">
          <span>{{ toMoney(item.MinReferencePrice) }}</span>
          <span>{{ toMoney(item.ReferencePrice) }}</span>
          <span>{{ toMoney(item.MaxReferencePrice) }}</span>
        </span>
        <span>
           <span class="trend" style="margin-right: 10px;width: 50px; display: inline-block" :style="{ color : item.Rise < 0 ? '#2b915c' : (item.Rise ==0?'#808080':'#c10b0b')}">{{ item.RiseRate }}</span>
          <span>
             <img v-if="item.Rise < 0" class="hand" src="@/assets/image/price/tread-green.png" style="width: 41px; height: 17px;" @click="handleTread(item, 2)">
             <img v-else class="hand" src="@/assets/image/price/tread-red.png" style="width: 41px; height: 17px;" @click="handleTread(item, 1)">
          </span>
        </span>
      </div>
    </div>
    <b-modal id="MarketPriceFuturesModal" v-model="modalShow" size="lg" button-size="sm" :title="listQuery.Country + ' - ' +listQuery.Part">
      <div class="item-box">
        <modal-chart v-if="list" :key="keyIndex" ref="MarketPriceFuturesTableChart" :data="list" :color="chartColor" y-axis-name="USD/ton" legend=""/>
      </div>
    </b-modal >
  </div>
</template>
<script>
import SelectorDatetime from './components/selector-datetime'
import Datetime from './components/datetime'
import Selector from '@/views/en/btcprice/components/table/components/picker'
import ModalChart from '../chart/index'
import { handleTableHeader1, getCountry, getCuts, getSeries } from '../../utils'
import commonmix from '@/utils/commonmix'
import { getLocalStore } from '@/utils/common'

export default {
  mixins: [commonmix],
  name: 'MarketPriceFuturesTable',
  components: {
    Selector,
    SelectorDatetime,
    Datetime,
    ModalChart
  },
  props: {
    tableList: {
      type: Array,
      default: () => []
    },
    defaultDate: {
      type: Array,
      default: () => []
    },
    isEnExpire: {
      type: Boolean,
      default: false
    },
    isPriceLogin: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    country () {
      return getCountry()
    },
    cuts () {
      return getCuts(this.defaultCountry)
    }
  },
  watch: {
    date (val) {
      this.$emit('handleDate', val)
    },
    closeSelect (val) {
      if (val) {
        this.isShow1 = false
        this.isShow2 = false
        this.closeSelect = false
      }
    }
  },
  data () {
    return {
      dateTimeList: this.defaultDate.slice(0, 4),
      listQuery: {
        UserID: getLocalStore().UserID,
        Part: 'Flank 80vl',
        Country: 'Brazil',
        RecommendDay: 0,
        FeedingMode: 'Grass-fed',
        Type: '_futures',
        IsFirst: true,
        IsEn: true
      },
      items: handleTableHeader1('', '_market_price'),
      chartColor: {},
      date: this.defaultDate[0],
      defaultCountry: 'All',
      defaultCuts: 'All',
      defaultName1: 'Country',
      defaultName2: 'Cuts',
      current: 3,
      current1: 0,
      current2: 0,
      keyIndex: 0,
      list: null,
      modalShow: false,
      closeSelect: false,
      isShow1: false,
      isShow2: false
    }
  },
  methods: {
    getData () {
      this.$store.dispatch('GetWebPriceDataIndex', this.listQuery).then(() => {
        const row = this.$store.getters.priceRow
        this.list = { categories: row.categories, series: getSeries(row.series) }
        if (this.$refs.MarketPriceFuturesTableChart) {
          this.$refs.MarketPriceFuturesTableChart.refreshData({ categories: row.categories, series: getSeries(row.series) })
        }
        this.keyIndex++
      }).catch((err) => {
        console.error(err)
      })
    },
    handleTabItem (item, type) {
      if (type === 1) {
        this.defaultName1 = item
        this.defaultCountry = item

        this.defaultName2 = 'Cuts'
        this.defaultCuts = 'All'
        this.current2 = 0
      } else {
        this.defaultName2 = item
        this.defaultCuts = item
      }

      this.$emit('handlePicker', {
        OriginPlace: this.defaultCountry,
        Part: this.defaultCuts
      })
    },
    handleTread (item, type) {
      if (this.$store.getters.token) {
        if (this.isEnExpire || item === this.tableList[0]) {
          if (type === 1) {
            this.chartColor = { Color1: '#c10b0b', Color2: '#FFFFFF' }
          } else {
            this.chartColor = { Color1: '#2b915c', Color2: '#FFFFFF' }
          }
          this.listQuery.Part = item.Part
          this.listQuery.Country = item.OriginPlace

          this.getData()
          this.modalShow = !this.modalShow
        } else {
          this.openMembershipModal()
        }
      } else {
        this.openLoginModal()
      }
    },
    handleInput (item) {
      this.current = 3
      const index = this.defaultDate.indexOf(item)
      this.dateTimeList = this.defaultDate.slice(index, index + 4)
    },
    toMoney (num) {
      num = parseFloat(num)
      if (isNaN(num)) {
        return '***'
      }
      num = num.toFixed(2)
      num = parseFloat(num)
      num = num.toLocaleString()
      return num
    },
    handleCloseSelect () {
      this.closeSelect = true
    },
    handleSelector (type) {
      if (type === 1) {
        this.isShow1 = !this.isShow1
        this.isShow2 = false
      } else {
        this.isShow1 = false
        this.isShow2 = !this.isShow2
      }
    },
    handleIsEnExpire () {
      if (this.$store.getters.token) {
        if (!this.isEnExpire) {
          this.openMembershipModal()
        }
      } else {
        this.openLoginModal()
      }
    }
  }
}
</script>

<style>
#MarketPriceFuturesModal .modal-dialog{
  top: 37%;
  left: 4.5%;
}

@media screen and (min-width: 2048px) {
  #MarketPriceFuturesModal .modal-dialog{
    top: 27%;
    left: 4.5%;
  }
}

#MarketPriceFuturesModal .modal-title{
  flex: 1;
  text-align: center;
  font-size: 18px;
}

#MarketPriceFuturesModal .modal-footer{
  display: none;
}
</style>

<style lang="scss" scoped>
$cell-width: 150px;
$cell-green: #2b915c;
$cell-red: #c10b0b;

.selector-box {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 10px;
}

.selector-calendar {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin: 10px;
}

.selector-left {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.point {
  text-align: center;
  margin: 0 10px;
  height: 3px;
  content: " ";
  border: 3px solid;
  border-radius: 3px;
}

.lower-height {
  font-size: 12px;
}

.rmb > span:first-child{
  color: $cell-green;
  font-size: 12px;
  margin-right: 10px;
}

.rmb > span:last-child{
  color: $cell-red;
  font-size: 12px;
  margin-left: 10px;
}

// 表头
.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 20px;
  font-size: 14px;
  font-weight: bold;
}

.header > div {
  min-width: $cell-width;
  text-align: center;
}

.ml-10 {
  margin-left: 10px;
}

::-webkit-scrollbar {
  display: none; /* Chrome Safari */
}

.table-box {
  margin-top: 10px;
  height: 715px;
  overflow-y: scroll;
}

.table-item {
  height: 35px;
  border-radius: 8px;
  background: #f1f2f3;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 5px 0;
}

.table-item > span {
  width: $cell-width;
  text-align: center;
  font-size: 14px;
}
</style>
