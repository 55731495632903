<template>
  <div id="visideModal" size="md" button-size="sm">
    <div class="form-box">
      <div class="title">My Price Idea</div>
      <form>
        <div class="form-item">
          <label class="form-label">BTC Price</label>
          <div class="form-content" style="margin-left: 20px;cursor: pointer;" @click="changePower(1)">
            <input type="radio" name="power" value="1" @change="changePower(1)" v-model="form.HighLow" style="margin-left:15px;" />
            <span style="margin-left: 7px;font-size: 14px;color: #A1A1A1;" :style="{ color: form.HighLow === 1 ? '#333333' : '#A1A1A1' }">Lower</span>
          </div>
          <div class="form-content" style="margin-left: 60px;cursor: pointer;" @click="changePower(2)">
            <span>
              <input type="radio" name="power" @change="changePower(2)" value="2" v-model="form.HighLow" />
              <span style="margin-left: 7px;font-size: 14px;color: #A1A1A1;" :style="{ color: form.HighLow === 2 ? '#333333' : '#A1A1A1' }">Higher</span>
            </span>
          </div>
        </div>
        <div class="form-item">
          <label class="form-label">My Price</label>
          <div class="form-content">
            <div style="margin-left: 25px;">
              <span><img src="@/assets/image/price/Button_jian.png" style="width: 24px;height: 24px;cursor: pointer;" @click="reduce" /></span>
            </div>
          </div>
          <div class="form-content">
            <div style="margin-left: 20px;">
              <span>
                <input disabled class="text-center1" type="text" v-model="form.DissentPrice" :style="{ color: confirmPrcie !== form.DissentPrice ? '#333333' : '#BCBCBC' }" />
              </span>
            </div>
            <div style="margin-left: 20px;"><span class="small">RMB/kg</span></div>
          </div>
          <div class="form-content">
            <div style="margin-left: 20px;"><img src="@/assets/image/price/Button_jia.png" style="width: 24px;height: 24px;cursor: pointer;" @click="add" /></div>
          </div>
        </div>
        <div class="form-item"><label class="form-label">Comments</label></div>
        <div class="form-item">
          <div class="form-content"><textarea class="tea-input" name="sign" v-model="form.Content" cols="58" rows="4"></textarea></div>
        </div>
        <div class="text-center">
          <button
            type="button"
            class="submit-btn"
            :style="{
              background: form.HighLow !== 0 || confirmPrcie !== form.DissentPrice ? '#266ca6' : '#fff',
              color: form.HighLow !== 0 || confirmPrcie !== form.DissentPrice ? '#fff' : '#B3B3B3'
            }"
            @click="submit"
          >
            Submit
          </button>
        </div>
      </form>
    </div>
   <b-modal id="MarketPriceSpotModal" v-model="isShowAlter" size="lg" button-size="sm" :footer="null" :title="null">
      <div>
        Sent successfully
      </div>
    </b-modal>
  </div>
</template>
<script>
import commonmix from '@/utils/commonmix'
import { getLocalStore } from '@/utils/common'
// import alter from '@/views/cn/btcprice/components/chart/alter.vue'

export default {
  mixins: [commonmix],
  name: 'advise',
  props: {
    data: {
      type: Object,
      default: () => {}
    }
  },
  components: {
    // alter
  },
  data () {
    return {
      isShowAlter: false,
      modalShow: false,
      form: {
        HighLow: 0,
        Content: '',
        DissentPrice: this.data.ReferencePrice
      },
      confirmPrcie: this.data.ReferencePrice,
      countPrice: this.data.ReferencePrice
    }
  },
  mounted () {
    console.log(this.data)
  },
  computed: {
    // chartDom () {
    //   return
    // }
  },
  methods: {
    towNumber (val) {
      return Number(val).toFixed(1)
    },
    changePower (val) {
      if (this.form.HighLow === 0) {
        this.form.HighLow = val
      } else if (this.form.HighLow === val) {
        this.form.HighLow = 0
      } else {
        this.form.HighLow = val
      }
    },
    add () {
      this.countPrice = Number(this.countPrice) + 0.1
      if (this.confirmPrcie === this.countPrice) {
        this.form.DissentPrice = this.confirmPrcie
      } else {
        this.form.DissentPrice = this.towNumber(this.countPrice)
      }
    },
    reduce () {
      this.countPrice = Number(this.countPrice) - 0.1
      if (this.confirmPrcie === this.countPrice) {
        this.form.DissentPrice = this.confirmPrcie
      } else {
        this.form.DissentPrice = this.towNumber(this.countPrice)
      }
    },
    submit () {
      if (this.form.HighLow === 0 && this.confirmPrcie === this.form.DissentPrice) {
        // console.log('请填写您的行情异议')
        this.$message({
          message: 'Please submit with your price idea.',
          type: 'warning'
        })
        return
      }
      console.log(this.form)
      // 提交接口
      const request = {
        ResearchID: this.data.ID,
        DissentPrice: this.form.DissentPrice,
        Cuts: this.data.Cuts,
        UserID: getLocalStore().UserID,
        HighLow: this.form.HighLow,
        PlantNo: this.data.EstNo,
        Content: this.form.Content,
        BeefPriceDataID: this.data.BeefPriceDataID,
        LanguageCode: 'en',
        FeedingMode: this.data.FeedingMode
      }
      console.log(request)
      this.$store.dispatch('AddWebBeefPriceDataOpinion', request).then(() => {
        this.$emit('sucess', false)
        this.$message({
          message: 'Sent successfully.',
          type: 'success'
        })
      }).catch((err) => {
        console.error(err)
        // this.$emit('sucess', false)
        this.$message.error('Sent failed.')
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.title {
  text-align: center;
  margin-bottom: 20px;
  font-size: 16px;
  font-family: Source Han Sans CN;
  font-weight: 500;
  line-height: 21px;
  color: #333333;
}

.form-item {
  display: flex;
  align-items: center;
  margin-bottom: 12px;
  font-size: 14px;
  font-family: Source Han Sans CN;
  font-weight: 400;
  line-height: 19px;
  color: #333333;
}

.form-label {
  padding: 6px 15px;
  width: 120px;
}

.small {
  display: inline-block;
  text-align: center;
  color: #888;
  min-width: 100px;
  font-size: 11px;
  font-family: PingFang SC;
  font-weight: 400;
  line-height: 16px;
  color: #bcbcbc;
  opacity: 1;
}

.text-center {
  text-align: center;
}

.text-center1 {
  text-align: center;
  margin-top: 14px;
  font-size: 14px;
  font-weight: 400;
  line-height: 13px;
  color: #a1a1a1;
  width: 93px;
  height: 36px;
  background: rgba(242, 243, 245, 0.39);
  border: 1px solid #eeeeee;
  opacity: 1;
  border-radius: 8px;
}

.computer {
  padding: 0;
  width: 26px;
  height: 26px;
  background-color: #409eff;
  color: #fff;
  border-radius: 50%;
  border: none;
  font-size: 16px;
}

textarea {
  padding: 6px 10px;
  color: #606266;
  background-color: #fff;
  background-image: none;
  border: 1px solid #dcdfe6;
  border-radius: 4px;
}
.submit-btn,
.price-btn {
  width: 62px;
  height: 27px;
  background: #fff;
  border: 1px solid #dcdfe6;
  color: #b3b3b3;
  font-family: Source Han Sans CN;
  -webkit-appearance: none;
  text-align: center;
  box-sizing: border-box;
  outline: none;
  margin: 0;
  transition: 0.1s;
  font-weight: 400;
  // padding: 12px 20px;
  font-size: 15px;
  border-radius: 4px;
}

.tea-input {
  background: rgba(242, 243, 245, 0.39);
  resize: none;
}
.tea-input:focus,
.tea-textarea:focus {
  border-color: #dcdfe6;
  outline: 0;
}
</style>
