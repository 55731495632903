<template>
  <div class="tab-item-box" :class="{ disabled: disabled }">
    <div v-for="(item, index) in items" :key="index" class="tab-item hand" :class="{ 'tab-active': current===index }" @click="handleClickItem(item, index)">{{ item.label }}</div>
  </div>
</template>
<script>
import commonmix from '@/utils/commonmix'

export default {
  mixins: [commonmix],
  name: 'Selector',
  props: {
    items: {
      type: Array,
      default: () => []
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      current: 0
    }
  },
  methods: {
    handleClickItem (item, index) {
      if (this.$store.getters.token) {
        this.current = index
        this.$emit('tab-item', item)
      } else {
        this.openLoginModal()
      }
    }
  }
}
</script>
<style lang="scss" scoped>
$item-height: 30px;
@function radius($args) {
  @return $args / 2
};

.tab-item-box {
  display: inline-flex;
  margin-right: 20px;
}
.tab-item {
  line-height: $item-height;
  padding: 0 15px;
  border: 1px solid #5f6162;
  font-weight: bold;
  font-size: 12px;
}
.tab-item:first-child {
  border-right: none;
  border-radius: radius($item-height) 0 0 radius($item-height);
}
.tab-item:last-child {
  border-left: none;
  border-radius: 0 radius($item-height) radius($item-height) 0;
}
.tab-active {
  color: #ffffff;
  background-color: #014478;
}
.disabled {
  pointer-events: none;
}
.disabled > div {
  color: #ffffff;
  background: rgb(175, 175, 175);
}
</style>
