<template>
  <div @click="handleCloseSelect">
    <div class="selector-box">
      <div class="selector-left">
        <picker :data="country" :is-show="isShow" :name="defaultName" :current.sync="current" :is-en-expire="isEnExpire" @click.native="handleSelector" @handleTabItem="handleTabItem" :close-select.sync="closeSelect"/>
        <div style="width: 175px;">
          <datetime :start.sync="date" selectedVariant="danger" :defaultDate="defaultDate" :is-en-expire="isEnExpire" :is-login="isPriceLogin"/>
        </div>
      </div>
      <div class="selector-left">
        <selector :items="upsDowns" :is-en-expire="isEnExpire" @tab-item="handleClickItem"/>
      </div>
    </div>
    <!--表格部分-->
    <div class="header">
      <div v-for="(item, index) in items" :key="index">
        <img class="" :src="item.icon">
        <span :class="{ 'ml-10': item.icon }">{{ item.label }}</span>
      </div>
    </div>
    <div class="table-box">
      <div v-for="(item, index) in tableListVisible" :key="index" class="table-item">
        <span>{{ item.OriginPlace }}</span>
        <span>{{ item.Part }}</span>
        <span @click="handleIsEnExpire">{{ item.ReferencePrice }}</span>
        <span @click="handleIsEnExpire">
          <span class="trend" :class="[item.Rise < 0 ? 'trend-green'  : ( item.Rise==0? 'trend-grey' : 'trend-red')]">{{ item.RiseRate }}</span>
        </span>
        <span>
          <img v-if="item.Rise < 0" class="hand" src="@/assets/image/price/tread-green.png" style="width: 41px; height: 27px;" @click="handleTread(item, 2)">
          <img v-else class="hand" src="@/assets/image/price/tread-red.png" style="width: 41px; height: 27px;" @click="handleTread(item, 1)">
        </span>
      </div>
    </div>
    <b-modal id="UpsAndDownsFuturesModal" v-model="modalShow" size="lg" button-size="sm" :title="listQuery.Country + ' - ' +listQuery.Part">
      <div class="item-box">
        <modal-chart v-if="list" :key="keyIndex" ref="UpsAndDownsFuturesTableChart" :data="list" :color="chartColor" y-axis-name="USD/kg" :legend="{}"/>
      </div>
    </b-modal >
  </div>
</template>
<script>
import Datetime from './components/selector-datetime'
import Picker from '@/views/en/btcprice/components/table/components/picker'
import Selector from '@/views/en/btcprice/components/table/components/selector'
import ModalChart from '../chart/index'
import { handleTableHeader, getCountry, sorting, getSeries } from '@/views/en/btcprice/utils'
import commonmix from '@/utils/commonmix'
import { getLocalStore } from '@/utils/common'

export default {
  mixins: [commonmix],
  name: 'UpsAndDownsFuturesTable',
  components: {
    Selector,
    Picker,
    Datetime,
    ModalChart
  },
  props: {
    tableList: {
      type: Array,
      default: () => []
    },
    defaultDate: {
      type: Array,
      default: () => []
    },
    isEnExpire: {
      type: Boolean,
      default: false
    },
    isPriceLogin: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    country () {
      return getCountry()
    },
    tableListVisible: {
      get () {
        const list = this.tableList
        sorting(list, 'Rise', this.sortingType)

        return list
      },
      set (val) {
        this.$emit('update:tableList', val)
      }
    }
  },
  watch: {
    date (val) {
      this.$emit('handleDate', val)
    },
    closeSelect (val) {
      if (val) {
        this.isShow = false
        this.closeSelect = false
      }
    }
  },
  data () {
    return {
      upsDowns: [
        { label: 'Ups', value: '_ups' },
        { label: 'Downs', value: '_downs' }
      ],
      items: handleTableHeader('', '_ups_downs'),
      listQuery: {
        UserID: getLocalStore().UserID,
        Part: 'Flank 80vl',
        Country: 'Brazil',
        RecommendDay: 0,
        FeedingMode: '',
        Type: '_futures',
        IsFirst: true,
        IsEn: true
      },
      chartColor: {},
      defaultCountry: 'All',
      defaultName: 'Country',
      sortingType: '_ups',
      date: this.defaultDate[0],
      list: null,
      modalShow: false,
      current: 0,
      keyIndex: 0,
      closeSelect: false,
      isShow: false
    }
  },
  methods: {
    getData () {
      this.$store.dispatch('GetWebPriceDataIndex', this.listQuery).then(() => {
        const row = this.$store.getters.priceRow
        this.list = { categories: row.categories, series: getSeries(row.series) }
        if (this.$refs.UpsAndDownsFuturesTableChart) {
          this.$refs.UpsAndDownsFuturesTableChart.refreshData({ categories: row.categories, series: getSeries(row.series) })
        }
        this.keyIndex++
      }).catch((err) => {
        console.error(err)
      })
    },
    handleTabItem (item) {
      this.defaultName = item
      this.defaultCountry = item
      this.$emit('handlePicker', {
        OriginPlace: this.defaultCountry
      })
    },
    handleClickItem (item) {
      this.sortingType = item.value
      sorting(this.tableListVisible, 'Rise', item.value)
    },
    handleTread (item, type) {
      if (this.$store.getters.token) {
        const firstItem = this.sortingType === '_ups' ? this.tableList[0] : this.tableList[this.tableList.length - 1]
        if (this.isEnExpire || item === firstItem) {
          if (type === 1) {
            this.chartColor = { Color1: '#c10b0b', Color2: '#FFFFFF' }
          } else {
            this.chartColor = { Color1: '#2b915c', Color2: '#FFFFFF' }
          }
          this.listQuery.Part = item.Part
          this.listQuery.Country = item.OriginPlace

          this.getData()
          this.modalShow = !this.modalShow
        } else {
          this.openMembershipModal()
        }
      } else {
        this.openLoginModal()
      }
    },
    handleCloseSelect () {
      this.closeSelect = true
    },
    handleSelector () {
      this.isShow = !this.isShow
    },
    handleIsEnExpire () {
      if (this.$store.getters.token) {
        if (!this.isEnExpire) {
          this.openMembershipModal()
        }
      } else {
        this.openLoginModal()
      }
    }
  }
}
</script>

<style>
#UpsAndDownsFuturesModal .modal-dialog{
  top: 23%;
  left: 4.5%;
}

@media screen and (min-width: 2048px) {
  #UpsAndDownsFuturesModal .modal-dialog{
    top: 17%;
    left: 4.5%;
  }
}

#UpsAndDownsFuturesModal .modal-title{
  flex: 1;
  text-align: center;
  font-size: 18px;
}

#UpsAndDownsFuturesModal .modal-footer{
  display: none;
}
</style>

<style lang="scss" scoped>
$cell-width: 150px;
$cell-green: #2b915c;
$cell-red: #c10b0b;

.selector-box {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 10px;
}

.selector-left {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.point {
  text-align: center;
  margin: 0 10px;
  height: 3px;
  content: " ";
  border: 3px solid;
  border-radius: 3px;
}

.lower-height {
  font-size: 12px;
}

.trend {
  width: 50px;
  height: 20px;
  color: #ffffff;
  padding: 1px 5px;
  border-radius: 4px;
  font-size: 14px;
}

.trend-green {
  background-color: $cell-green;
}

.trend-red {
  background-color: $cell-red;
}

.trend-grey {
  background-color: #808080;
}

// 表头
.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 20px;
  font-size: 14px;
  font-weight: bold;
}

.header > div {
  min-width: $cell-width;
  text-align: center;
}

.ml-10 {
  margin-left: 10px;
}

::-webkit-scrollbar {
  display: none; /* Chrome Safari */
}

.table-box {
  margin-top: 10px;
  height: 840px;
  overflow-y: scroll;
}

.table-item {
  height: 35px;
  border-radius: 8px;
  background: #f1f2f3;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 5px 0;
}

.table-item > span {
  width: $cell-width;
  text-align: center;
  font-size: 14px;
}
</style>
