<template>
  <div @click="handleCloseSelect">
    <div class="selector-calendar">
      <div style="width: 175px;">
        <!-- <selector-datetime :start.sync="date" :defaultDate="defaultDate" :is-login="isPriceLogin" :selectedVariant="feedingMode === 'Grass-fed' ? 'success': 'warning'" @input="handleInput"/> -->
        <selector-datetime :start.sync="date" :defaultDate="defaultDate" :is-login="isPriceLogin" :selectedVariant="feedingMode === 'Grass-fed' ? 'success': 'warning'" @input="handleInput"/>
      </div>
      <img v-if="feedingMode === 'Grass-fed'" src="@/assets/image/price/success/calendar.png" style="margin-left: 20px" alt="">
      <img v-if="feedingMode === 'Grain-fed'" src="@/assets/image/price/warning/calendar.png" style="margin-left: 20px" alt="">
    </div>
    <div style="margin: 0;padding: 0">
      <datetime :current.sync="current" :start.sync="date" :data="dateTimeList" :is-en-expire="isEnExpire" :color="feedingMode === 'Grass-fed' ? '#2b915c': '#e27516'"/>
    </div>
    <div class="selector-box">
      <div class="selector-left">
        <selector :data="country" :name="defaultName1" :is-show="isShow1" :current.sync="current1" :is-en-expire="isEnExpire" @click.native="handleSelector(1)" @handleTabItem="handleTabItem($event, 1)"/>
        <selector :data="cuts" :name="defaultName2" :is-show="isShow2" :current.sync="current2" :is-en-expire="isEnExpire" @click.native="handleSelector(2)" @handleTabItem="handleTabItem($event, 2)"/>
        <img v-if="feedingMode === 'Grass-fed'&& isShow3===false" src="@/assets/image/price/Button_USD.png" style="cursor: pointer; width: 100px;height: 30px;" @click="changeCurrency" >
        <img v-if="feedingMode === 'Grass-fed'&& isShow3===true" src="@/assets/image/price/Button_RMB.png" style="cursor: pointer;" @click="changeCurrency" >
        <img v-if="feedingMode === 'Grain-fed'&& isShow4===false" src="@/assets/image/price/Button_USD.png" style="cursor: pointer; width: 100px;height: 30px;" @click="changeCurrency1" >
        <img v-if="feedingMode === 'Grain-fed'&& isShow4===true" src="@/assets/image/price/Button_RMB.png" style="cursor: pointer;" @click="changeCurrency1" >
        <div class="my-img">
          <div :id="'tooltip-img'"><img src="@/assets/image/price/wenhao.png" style="margin-left: 3px;margin-bottom: 20px !important;"></div>
          <b-tooltip placement="bottom" :target="'tooltip-img'" triggers="hover" >
            <img src="@/assets/image/huilv.png" style="width: 540px;height: 225px">
          </b-tooltip>
        </div>
      </div>
      <div class="selector-left lower-height">
        <div class="selector-left">
          <div class="point" style="color:#2b915c;"/>
          <div>Represents a lower price</div>
        </div>
        <div class="selector-left">
          <div class="point" style="color:#c10b0b;"/>
          <div>Represents a higher price</div>
        </div>
      </div>
    </div>
    <!--表格部分-->
    <div v-if="feedingMode === 'Grass-fed'" class="header">
      <div  v-for="(item, index) in items" :key="index">
        <img class="" :src="item.icon">
        <span :class="{ 'ml-10': item.icon }">{{ item.label }}</span>
      </div>
    </div>
    <div v-if="feedingMode === 'Grain-fed'" class="header">
      <div v-for="(item1, index1) in items1" :key="index1">
        <img class="" :src="item1.icon">
        <span :class="{ 'ml-10': item1.icon }">{{ item1.label }}</span>
      </div>
    </div>
    <div class="table-box">
      <div v-for="(item, index) in tableList" :key="index">
        <div class="table-item">
        <span>{{ item.OriginPlace }}</span>
        <span>{{ item.Part }}</span>
        <span v-if="feedingMode === 'Grass-fed'&& isShow3===false" class="rmb" @click="handleIsEnExpire">
          <span>{{ toMoney(item.MinReferencePrice) }}</span>
          <span>{{ toMoney(item.ReferencePrice) }}</span>
          <span>{{ toMoney(item.MaxReferencePrice) }}</span>
        </span>
        <span v-if="feedingMode === 'Grass-fed'&& isShow3===true" class="rmb" @click="handleIsEnExpire">
          <span>{{ toMoney1(item.DMinReferencePrice)}}</span>
          <span>{{ toMoney1(item.DReferencePrice)}}</span>
          <span>{{ toMoney1(item.DMaxReferencePrice) }}</span>
        </span>
        <span v-if="feedingMode === 'Grain-fed'&& isShow4===false" class="rmb" @click="handleIsEnExpire">
          <span>{{ toMoney(item.MinReferencePrice) }}</span>
          <span>{{ toMoney(item.ReferencePrice) }}</span>
          <span>{{ toMoney(item.MaxReferencePrice) }}</span>
        </span>
        <span v-if="feedingMode === 'Grain-fed'&& isShow4===true" class="rmb" @click="handleIsEnExpire">
          <span>{{ toMoney1(item.DMinReferencePrice)}}</span>
          <span>{{ toMoney1(item.DReferencePrice)}}</span>
          <span>{{ toMoney1(item.DMaxReferencePrice) }}</span>
        </span>
        <span v-if="item.CattleType !=''&& item.Level !=''&& item.FeedingDays !=0">{{item.CattleType}} {{ item.Level }} {{item.FeedingDays}}-day</span>
        <span v-else-if="item.CattleType ===''&& item.Level !=''&& item.FeedingDays !=0">{{item.CattleType}} {{ item.Level }} {{item.FeedingDays}}-day</span>
        <span v-else-if="item.CattleType ===''&& item.Level ===''">{{ item.CattleType }}</span>
        <span v-else-if="item.CattleType !=''&& item.Level ===''">{{ item.CattleType }}</span>
        <span v-else-if="item.CattleType !=''&& item.Level !=''&& item.FeedingDays ===0">{{item.CattleType}} {{ item.Level }}</span>
        <span v-else-if="item.CattleType ===''&& item.Level !=''&& item.FeedingDays ===0">{{ item.Level }}</span>
        <span v-else-if="item.Level ===''&& item.FeedingDays !=0">{{ item.FeedingDays }}-day</span>
<!--        <span>
           <span class="trend" style="margin-right: 10px; width: 50px; display: inline-block" :style="{ color : item.Rise < 0 ? '#2b915c' : '#c10b0b'}">{{ item.RiseRate }}</span>
          <span>
            <img v-if="item.Rise < 0" class="hand" src="@/assets/image/price/tread-green.png" style="width: 50px; height: 21px;" @click="handleTread(item, 2)">
            <img v-else class="hand" src="@/assets/image/price/tread-red.png" style="width: 50px; height: 21px;" @click="handleTread(item, 1)">
          </span>
        </span> -->
        <span style="display: flex;justify-content: space-between;text-align: center;align-items: center;padding-left: 20px;padding-right: 20px;">
           <span class="trend" style="margin-right: 10px; width: 50px; display: inline-block" :style="{ color : item.Rise < 0 ? '#2b915c' : (item.Rise == 0 ? '#808080' : '#DD232A')}" @click="handleIsEnExpire2(item)">{{ item.RiseRate }}</span>
          <span style="text-align: center;align-items: center;display: flex;flex-direction: column;cursor: pointer;" @click="handleTread(item, item.Rise < 0 ? 2 : 1)">
            <span>
              <img v-if="item.Rise < 0" class="hand" src="@/assets/image/price/Icon_zhishudie.png">
              <img v-if="item.Rise == 0" class="hand" src="@/assets/image/price/Icon_zhishuping.png">
              <img v-if="item.Rise > 0" class="hand" src="@/assets/image/price/Icon_zhishuzhang.png">
            </span>
            <span style="font-size: 9px;" v-if="item.IndexPrice" :style="{ color : item.Rise < 0 ? '#07AC8E' : (item.Rise == 0 ? '#808080' : '#DD232A')}">{{ toMoney2 (item.IndexPrice)}}</span>
          </span>
        </span>
        <span>
            <img style="margin-left: 20px;" :style="{transform: (currentPlant === index && plantShow)  ? 'rotate(180deg)' : ''}" v-if="item.IsPlantNum === 1" class="hand" src="@/assets/image/price/Icon_zhankai.png"  @click="handlePlantShow(item, index)">
        </span>
        </div>
        <div v-if="plantShow && currentPlant === index && researchList && researchList.length > 0" class="plant-no">
          <div v-for="(item2, index2) in researchList" :key="index2">
            <div  class="table-plant">
              <span>{{ item2.EstNo }}</span>
                  <span></span>
                  <span class="rmb" @click="handleIsEnExpire">
                    <span></span>
                    <span v-if="feedingMode === 'Grass-fed'">{{ !isShow3 ? toMoney(item2.ReferencePrice) :toMoney1(item2.ReferenceUSDPrice) }}</span>
                    <span v-if="feedingMode === 'Grain-fed'">{{ !isShow4 ? toMoney(item2.ReferencePrice) :toMoney1(item2.ReferenceUSDPrice) }}</span>
                    <span></span>
                  </span>
                  <span>{{ item2.Notes }}</span>
                  <span style="display: flex;justify-content: space-between;text-align: center;align-items: center;padding-left: 20px;padding-right: 20px;">
                     <span class="trend" style="margin-right: 10px; width: 50px; display: inline-block" :style="{ color : item2.Rise < 0 ? '#07AC8E' : (item2.Rise==0 ? '#808080' : '#DD232A')}" @click="handleIsEnExpire3(item2)">{{ item2.RiseRate }}</span>
                    <span style="text-align: center;align-items: center;display: flex;flex-direction: column;" @click="handlePantTread(item2, item2.Rise < 0 ? 2 : 1)">
                      <span>
                        <img v-if="item2.Rise < 0" class="hand" src="@/assets/image/price/Icon_die.png" >
                        <img v-else-if="item2.Rise == 0" class="hand" src="@/assets/image/price/Icon_ping.png" >
                        <img v-else-if="item2.Rise > 0" class="hand" src="@/assets/image/price/Icon_zhang.png">
                      </span>
                    </span>
                  </span>
                  <span>
                      <img class="hand" style="margin-left: 20px;" src="@/assets/image/price/Icon_Doubts.png"  @click="handleyiyiShow(item2, index2)">
                  </span>
            </div>
            <div class="xiahuaxian"></div>
          </div>
        </div>
      </div>
    </div>
<!--    <b-modal id="MarketPriceSpotModal" v-model="modalShow" size="lg" button-size="sm" :title="listQuery.Country + ' - ' +listQuery.Part">
      <div class="item-box">
        <modal-chart v-if="list" :key="keyIndex" ref="MarketPriceSpotTableChart" :data="list" :color="chartColor" legend=""/>
      </div>
    </b-modal> -->
   <b-modal id="MarketPriceSpotModal" v-model="modalShow" size="lg" button-size="sm" :title="listQuery.Country + ' - ' +listQuery.Part">
      <div class="item-box">
        <div v-if="isShowIndex" class="price-change">
          <div @click="changePrice(1)" class="price-index" :style="{ color : isPriceIndex ? '#FFFFFF' : '#666666', background : isPriceIndex ? '#006EB9' : '#FFFFFF'}">Index</div>
          <div @click="changePrice(2)" class="price-list" :style="{ color : isPriceData ? '#FFFFFF' : '#666666', background : isPriceData ? '#006EB9' : '#FFFFFF'}">Price</div>
        </div>
      </div>
      <div class="item-box">
        <modal-chart v-if="list && isPriceData && !isPriceIndex" :key="keyIndex" ref="MarketPriceSpotTableChart" :data="list" :color="chartColor"/>
        <price-chart v-if="priceIndexList && isPriceIndex && !isPriceData" :key="keyIndex2" ref="MarketPriceIndexTableChart" :data="priceIndexList" :color="chartColor"/>
      </div>
    </b-modal>
    <b-modal id="AddopinionModal" hide-footer v-model="opinionShow" size="md" button-size="sm" :footer="null" :title="title">
      <div>
        <add-opinion id="AddOpinion" v-model="opinionShow" :data="opinionData" title="" @sucess="sucessReturn"/>
      </div>
    </b-modal>
    <b-modal id="MarketPriceSpotModal" v-model="isPlantIndex" size="lg" button-size="sm" :footer="null" :title="title2">
      <div>
         <plant-chart ref="MarketPlantSpotTableChart" v-if="plantIndexList && isPlantIndex && !isPriceIndex && !isPriceData" :key="keyIndex3" :data="plantIndexList" :color="chartColor"/>
      </div>
    </b-modal>
  </div>
</template>
<script>
import SelectorDatetime from './components/selector-datetime'
import Datetime from './components/datetime'
import Selector from '@/views/en/btcprice/components/table/components/picker'
import ModalChart from '../chart/index'
import { handleTableHeader, getCountry, getCuts, getSeries } from '../../utils'
import commonmix from '@/utils/commonmix'
import { getLocalStore } from '@/utils/common'
import '@/styles/entooltip.css'
import PlantChart from '../chart/plant-index.vue'
import AddOpinion from './components/add-opinion'
import PriceChart from '../chart/price-index.vue'

export default {
  mixins: [commonmix],
  name: 'MarketPriceSpotTable',
  components: {
    Selector,
    SelectorDatetime,
    Datetime,
    ModalChart,
    PlantChart,
    AddOpinion,
    PriceChart
  },
  props: {
    feedingMode: {
      type: String,
      default: () => ''
    },
    tableList: {
      type: Array,
      default: () => []
    },
    // items: {
    //   type: Array,
    //   default: () => []
    // },
    defaultDate: {
      type: Array,
      default: () => []
    },
    isSwitchNavBar: {
      type: Boolean,
      default: false
    },
    isEnExpire: {
      type: Boolean,
      default: false
    },
    isPriceLogin: {
      type: Boolean,
      default: false
    },
    reportID: {
      type: Number,
      default: () => 0
    }
  },
  computed: {
    country () {
      return getCountry()
    },
    cuts () {
      return getCuts(this.defaultCountry)
    },
    isSwitchNavBarVisible: {
      get () {
        return this.isSwitchNavBar
      },
      set (val) {
        this.$emit('update:isSwitchNavBar', val)
      }
    }
  },
  watch: {
    isSwitchNavBarVisible (val) {
      if (val) {
        // 切换了TabBar
        this.defaultCountry = 'All'
        this.defaultCuts = 'All'
        this.defaultName1 = 'Country'
        this.defaultName2 = 'Cuts'
        this.current1 = 0
        this.current2 = 0
        this.listQuery.FeedingMode = this.feedingMode
        this.date = this.defaultDate[0]
        this.current = 6
        this.closeSelect = true

        this.isSwitchNavBarVisible = false
      }
    },
    date (val) {
      this.$emit('handleDate', val)
    },
    closeSelect (val) {
      if (val) {
        this.isShow1 = false
        this.isShow2 = false
        this.closeSelect = false
      }
    },
    feedingMode (val) {
      if (val === 'Grain-fed') {
        this.plantShow = false
      }
    },
    tableList (val) {
      if (val) {
        this.plantShow = false
      }
    }
  },
  data () {
    return {
      dateTimeList: this.defaultDate.slice(0, 7),
      items: handleTableHeader('Grass-fed', '_market_price', 'RMB'),
      items1: handleTableHeader('Grain-fed', '_market_price', 'RMB'),
      listQuery: {
        UserID: getLocalStore().UserID,
        Part: 'Flank 80vl',
        Country: 'Brazil',
        RecommendDay: 0,
        FeedingMode: 'Grass-fed',
        Type: '_spotgoods',
        IsFirst: true,
        IsEn: true
      },
      chartColor: {},
      date: this.defaultDate[0],
      defaultCountry: 'All',
      defaultCuts: 'All',
      defaultName1: 'Country',
      defaultName2: 'Cuts',
      current1: 0,
      current2: 0,
      current: 6,
      keyIndex: 0,
      list: null,
      modalShow: false,
      closeSelect: false,
      isShow1: false,
      isShow2: false,
      isShow3: false,
      isShow4: false,
      plantShow: false,
      currentPlant: 0,
      researchList: [],
      plantIndexList: [],
      isPlantIndex: false,
      isPriceIndex: false,
      isPriceData: false,
      keyIndex2: 0,
      keyIndex3: 0,
      opinionShow: false,
      opinionData: {},
      title: '',
      title2: '',
      priceIndexList: [],
      isShowIndex: false
    }
  },
  methods: {
    changePrice (val) {
      if (val === 1) {
        this.isPriceIndex = true
        this.isPriceData = false
      }
      if (val === 2) {
        this.isPriceIndex = false
        this.isPriceData = true
      }
    },
    getPriceIndexData (item) {
      this.$store.dispatch('GetWebBeefPriceDataIndex', {
        UserID: getLocalStore().UserID,
        Part: item.Part,
        Origin: item.OriginPlace,
        ReportID: this.reportID,
        LanguageCode: 'en',
        FeedingMode: this.listQuery.FeedingMode
      }).then(() => {
        const row = this.$store.getters.priceRows
        this.priceIndexList = { categories: row.categories, series: this.getSeries1(row.series) }
        if (this.$refs.MarketPriceIndexTableChart) {
          this.$refs.MarketPriceIndexTableChart.refreshData({ categories: row.categories, series: this.getSeries1(row.series) })
        }
        this.keyIndex2++
      }).catch((err) => {
        console.error(err)
      })
    },
    sucessReturn (val) {
      this.opinionShow = false
    },
    handleyiyiShow (item) {
      if (this.$store.getters.token) {
        if (!this.isEnExpire && item !== this.researchList[0]) {
          // this.openCnMembershipModal()
        } else {
          this.title = item.Origin + '-' + item.Cuts + '-' + item.EstNo
          this.opinionShow = true
          item.ReferencePrice = this.toMoney(item.ReferencePrice)
          item.BeefPriceDataID = this.BeefPriceDataID
          this.opinionData = item
        }
      } else {
        // this.openCnLoginModal()
      }
    },
    getSeries1 (data, type = false) {
      const arr = []
      data.forEach(function (item) {
        arr.push({ name: item.name, data: item.data, type: 'line' })
      })
      return arr
    },
    getPlantIndexData (item) {
      this.$store.dispatch('GetWebBeefPriceDataResearchIndex', {
        UserID: getLocalStore().UserID,
        Part: item.Cuts,
        OriginPlace: item.Origin,
        EstNo: item.EstNo,
        Notes: item.Notes,
        ReportID: this.reportID,
        LanguageCode: 'en',
        FeedingMode: this.listQuery.FeedingMode
      }).then(() => {
        const row = this.$store.getters.priceRows
        this.plantIndexList = { categories: row.categories, series: this.getSeries1(row.series) }
        if (this.$refs.MarketPlantSpotTableChart) {
          this.$refs.MarketPlantSpotTableChart.refreshData({ categories: row.categories, series: this.getSeries1(row.series) })
        }
        this.keyIndex3++
      }).catch((err) => {
        console.error(err)
      })
    },
    handlePantTread (item, type) {
      if (this.$store.getters.token) {
        if (this.isEnExpire || item === this.researchList[0]) {
          if (item.Notes === null || item.Notes === '') {
            this.title2 = item.Origin + '-' + item.Cuts + '-' + item.EstNo
          } else {
            this.title2 = item.Origin + '-' + item.Cuts + '-' + item.EstNo + '-' + item.Notes
          }
          this.getPlantIndexData(item)
          this.isShowIndex = false
          this.isPriceIndex = false
          this.isPriceData = false
          this.modalShow = false
          this.isPlantIndex = true
          this.getData()
        } else {
          // this.openMembershipModal()
        }
      } else {
        // this.openLoginModal()
      }
    },
    toMoney2 (num) {
      num = parseFloat(num)
      if (isNaN(num)) {
        return '***'
      }
      num = num.toFixed(2)
      return num.replace(/\B(?=(\d{3})+\b)/g, ',')
    },
    getResearchList (item) {
      this.researchList = []
      this.$store.dispatch('GetWebBeefPriceDataResearchList', {
        UserID: getLocalStore().UserID,
        Part: item.Part,
        OriginPlace: item.OriginPlace,
        ReportID: this.reportID,
        LanguageCode: 'en',
        FeedingMode: this.listQuery.FeedingMode
      }).then(() => {
        const row = this.$store.getters.priceRows
        this.researchList = row
      }).catch((err) => {
        console.error(err)
      })
    },
    handlePlantShow (item, index) {
      if (this.$store.getters.token) {
        if (this.isEnExpire || item === this.tableList[0]) {
          if (this.plantShow) {
            if (index !== this.currentPlant) {
              this.plantShow = true
            } else {
              this.plantShow = false
            }
          } else {
            this.plantShow = true
          }
          this.currentPlant = index
          this.BeefPriceDataID = item.BeefPriceDataID
          this.getResearchList(item)
        } else {
          this.openMembershipModal()
        }
      } else {
        this.openLoginModal()
      }
    },
    getData () {
      this.$store.dispatch('GetWebPriceDataIndex', this.listQuery).then(() => {
        const row = this.$store.getters.priceRow
        this.list = { categories: row.categories, series: getSeries(row.series) }
        if (this.$refs.MarketPriceSpotTableChart) {
          this.$refs.MarketPriceSpotTableChart.refreshData({ categories: row.categories, series: getSeries(row.series) })
        }
        this.keyIndex++
      }).catch((err) => {
        console.error(err)
      })
    },
    changeCurrency () {
      // 改变表头
      if (this.isShow3 === false) {
        this.items = handleTableHeader('Grass-fed', '_market_price', 'USD')
        this.isShow3 = true
      } else {
        this.items = handleTableHeader('Grass-fed', '_market_price', 'RMB')
        this.isShow3 = false
      }
      // 是否需要通知父组件给列表刷新数据
      this.$emit('changeCurrency')
    },
    changeCurrency1 () {
      // 改变表头
      if (this.isShow4 === false) {
        this.items1 = handleTableHeader('Grain-fed', '_market_price', 'USD')
        this.isShow4 = true
      } else {
        this.items1 = handleTableHeader('Grain-fed', '_market_price', 'RMB')
        this.isShow4 = false
      }
      // 是否需要通知父组件给列表刷新数据
      this.$emit('changeCurrency')
    },
    handleTabItem (item, type) {
      if (type === 1) {
        this.defaultName1 = item
        this.defaultCountry = item

        this.defaultName2 = 'Cuts'
        this.defaultCuts = 'All'
        this.current2 = 0
      } else {
        this.defaultName2 = item
        this.defaultCuts = item
      }

      this.$emit('handlePicker', {
        OriginPlace: this.defaultCountry,
        Part: this.defaultCuts
      })
    },
    // handleTread (item, type) {
    //   if (this.$store.getters.token) {
    //     if (this.isEnExpire || item === this.tableList[0]) {
    //       if (type === 1) {
    //         this.chartColor = { Color1: '#c10b0b', Color2: '#FFFFFF' }
    //       } else {
    //         this.chartColor = { Color1: '#2b915c', Color2: '#FFFFFF' }
    //       }
    //       this.listQuery.Part = item.Part
    //       this.listQuery.Country = item.OriginPlace

    //       this.getData()
    //       this.modalShow = !this.modalShow
    //     } else {
    //       this.openMembershipModal()
    //     }
    //   } else {
    //     this.openLoginModal()
    //   }
    // },
    handleTread (item, type) {
      if (this.$store.getters.token) {
        if (this.isEnExpire || item === this.tableList[0]) {
          if (type === 1) {
            this.chartColor = { Color1: '#c10b0b', Color2: '#FFFFFF' }
          } else {
            this.chartColor = { Color1: '#2b915c', Color2: '#FFFFFF' }
          }
          this.listQuery.Part = item.Part
          this.listQuery.Country = item.OriginPlace
          if (item.IndexPrice) {
            this.isShowIndex = true
            this.isPriceIndex = true
            this.isPriceData = false
            this.getPriceIndexData(item)
          } else {
            this.isShowIndex = false
            this.isPriceIndex = false
            this.isPriceData = true
          }
          this.getData()
          this.modalShow = !this.modalShow
        } else {
          this.openCnMembershipModal()
        }
      } else {
        this.openCnLoginModal()
      }
    },
    handleInput (item) {
      this.current = 6
      const index = this.defaultDate.indexOf(item)
      this.dateTimeList = this.defaultDate.slice(index, index + 6)
    },
    toMoney (num) {
      num = parseFloat(num)
      if (isNaN(num)) {
        return '***'
      }
      num = num.toFixed(1)
      return num.replace(/\B(?=(\d{3})+\b)/g, ',')
    },
    toMoney1 (num) {
      if (num !== '******') {
        num = parseFloat(num)
        if (isNaN(num)) {
          return ''
        }
        num = num.toFixed(0)
        return num.replace(/\B(?=(\d{3})+\b)/g, ',')
      } else {
        return '***'
      }
    },
    handleCloseSelect () {
      this.closeSelect = true
    },
    handleSelector (type) {
      if (type === 1) {
        this.isShow1 = !this.isShow1
        this.isShow2 = false
      } else {
        this.isShow1 = false
        this.isShow2 = !this.isShow2
      }
    },
    handleIsEnExpire () {
      if (this.$store.getters.token) {
        if (!this.isEnExpire) {
          this.openMembershipModal()
        }
      } else {
        this.openLoginModal()
      }
    }
  }
}
</script>

<style>
#MarketPriceSpotModal .modal-dialog{
  top: 41%;
  left: 4.5%;
}

@media screen and (min-width: 2048px) {
  #MarketPriceSpotModal .modal-dialog{
    top: 30%;
    left: 4.5%;
  }
}

#MarketPriceSpotModal .modal-title{
  flex: 1;
  text-align: center;
  font-size: 18px;
}

#MarketPriceSpotModal .modal-footer{
  display: none;
}

#AddopinionModal .modal-header{
  border-bottom: none;
}

#AddopinionModal .modal-dialog{
  top: 21%;
  left: 4.5%;
  width: 534px;
  height: 350px;
  background: rgba(255, 255, 255, 0.39);
  opacity: 1;
}

#AddopinionModal .modal-title{
  flex: 1;
  text-align: left;
  font-size: 14px !important;
  font-family: Source Han Sans CN !important;
  font-weight: 400 !important;
  color: #999999 !important;
  opacity: 1;
}
</style>

<style lang="scss" scoped>
$cell-width: 150px;
$cell-green: #2b915c;
$cell-red: #c10b0b;

.selector-box {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 10px;
}

.selector-calendar {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin: 10px;
}

.selector-left {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.point {
  text-align: center;
  margin: 0 10px;
  height: 3px;
  content: " ";
  border: 3px solid;
  border-radius: 3px;
}

.lower-height {
  font-size: 12px;
}

.rmb > span:first-child{
  color: $cell-green;
  font-size: 12px;
  margin-right: 10px;
}

.rmb > span:last-child{
  color: $cell-red;
  font-size: 12px;
  margin-left: 10px;
}

// 表头
.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 20px;
  font-size: 14px;
  font-weight: bold;
}

.header > div {
  min-width: $cell-width;
  text-align: center;
}

.ml-10 {
  margin-left: 10px;
}

::-webkit-scrollbar {
  display: none; /* Chrome Safari */
}

.table-box {
  margin-top: 10px;
  height: 715px;
  overflow-y: scroll;
}

// .table-item {
//   height: 35px;
//   border-radius: 8px;
//   background: #f1f2f3;
//   display: flex;
//   align-items: center;
//   justify-content: space-between;
//   margin: 5px 0;
// }

.table-item > span {
  width: $cell-width;
  text-align: center;
  font-size: 14px;
}

.table-item {
  height: 38px;
  border-radius: 8px;
  background: #f1f2f3;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 5px 0;
  padding-left: 6px;
  padding-right: 6px;
}

.plant-no {
  // width: 842px;
  height: auto;
  // margin: 6px 24px 6px 12px;
  margin: 6px 6px 6px 6px;
  background: rgba(255, 255, 255, 0.39);
  box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.16);
  opacity: 1;
  border-radius: 8px;
  padding-bottom: 10px;
}

.table-plant {
  height: 34px;
  border-radius: 8px;
  // background: #f1f2f3;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 5px 0;
}

.table-plant:hover{
  background: #F2F2F2;
}

.table-plant > span {
  width: $cell-width;
  text-align: center;
  font-size: 14px;
}

.xiahuaxian{
  height: 0px;
  border: 1px solid #F5F5F5;
  opacity: 1;
  margin-left: 20px;
  margin-right: 39px;
}

.price-change{
  display: flex;
  width: 100%;
  justify-content: flex-end;
  padding-right: 36px;
}

.price-index{
  width: 34px;
  height: 20px;
  border: 1px solid #666666;
  opacity: 1;
  border-radius: 2px;
  text-align: center;
  align-items: center;
  font-size: 12px;
  font-family: Source Han Sans CN;
  font-weight: 400;
  opacity: 1;
  cursor: pointer;
  margin-right: 10px;
}

.price-list{
  cursor: pointer;
  width: 34px;
  height: 20px;
  border: 1px solid #666666;
  opacity: 1;
  border-radius: 2px;
  text-align: center;
  align-items: center;
  font-size: 12px;
  font-family: Source Han Sans CN;
  font-weight: 400;
  opacity: 1;
}
</style>
